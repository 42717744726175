import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Success from './components/Success';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/main.css';
import Form from './Form';

function App() {
  
  return (
      <Router>
        <Routes>
          <Route path="/podziekowanie" element={<Success />} />
          <Route path="/" element={<Form />} />
          <Route path="/pl" element={<Form />} />
          <Route path="/en" element={<Form />} />
          <Route path="/de" element={<Form />} />
          <Route path="/cs" element={<Form />} />
          <Route path="/fr" element={<Form />} />
          <Route path="/fi" element={<Form />} />
          <Route path="/es" element={<Form />} />
          <Route path="/be" element={<Form />} />
          <Route path="/uks" element={<Form />} />
          <Route path="/aut" element={<Form />} />
        </Routes>
      </Router>
  );
}

export default App;
